import { packageVersion } from './package-version';

export const environment = {
  production: true,
  env: 'demo',
  version: packageVersion,
  sentry: {
    dsn: 'https://ebe64054fde449e3b139526783c59bc2@o433783.ingest.sentry.io/4505588328366080',
  },
  googleAnalyticsTrackingId: 'G-5NLHWV3EVH',
  api: 'https://backend-demo.legalbot.cl/api',
};
